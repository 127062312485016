import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsCart } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa6';
import { Container, Row, Col } from 'react-bootstrap';
import SearchBar from './SearchBar'; // Import the SearchBar component
import './Header.css';
import axios from "axios";
import logo from "./Images/favicon.jpeg";
import { FaRegHeart } from "react-icons/fa";

const Header = () => {
    const [username, setUsername] = useState(localStorage.getItem('username') || "");
    const [cartProductsCount, setCartProductsCount] = useState(0); // Initialize cart products count to 0
    const [wishlistCount, setWishlistCount] = useState(0);
    localStorage.setItem("cartcount", cartProductsCount.cartcount);
    localStorage.setItem("wishlistcount", wishlistCount);


    useEffect(() => {
        const fetchCartProductsCount = async () => {
            const cartId = localStorage.getItem('cart_id');
            try {
                const cartResponse = await axios.get(
                    `https://itilmaamapi.adef.tech/Cartcount?cart_id=${cartId}`
                );
                if (cartResponse.status === 200) {
                    setCartProductsCount(cartResponse.data);
                }
            } catch (error) {
                console.error("Error fetching cart details:", error);
            }
        };
        debugger;
        const fetchWishlistCount = async () => {
            const authorId = localStorage.getItem('author_id');
            try {
                const wishlistResponse = await axios.get(
                    `https://itilmaamapi.adef.tech/Wishlistcount?authorid=${authorId}`
                );
                console.log("Wishlist Response:", wishlistResponse.data);
                if (wishlistResponse.status === 200) {
                    setWishlistCount(wishlistResponse.data.wishlistcount);
                }
            } catch (error) {
                console.error("Error fetching wishlist details:", error);
            }
        };

        fetchCartProductsCount();
        fetchWishlistCount();
    }, []);


    return (
        <Container>
            <Row className="header align-items-center">
                <Col xs={6} md={3} className="header__logo">
                    <Link to="/">
                        <img src={logo} className='logo' alt="Logo" /> ITILMAAM
                    </Link>
                </Col>
                <Col xs={12} md={6} className="header__search d-none d-md-flex">
                    <SearchBar />
                </Col>
                <Col xs={6} md={3} className="header__icons d-flex justify-content-end">
                    {/* Wishlist Icon */}
                    <div className="header__icon">
                        <Link className='link' to="/wishlist">
                            <FaRegHeart className="wishlist-icon" />
                            <span className="cart-count">{wishlistCount}</span>
                        </Link>
                    </div>
                    <div className="header__icon">
                        <Link className='link' to="/cart">
                            <BsCart className="cart-icon" />
                            <span className="cart-count">{cartProductsCount.cartcount}</span>
                        </Link>
                    </div>
                    {username ? (
                        <div className="header__icon">
                            <Link to="/profile" className="link">
                                <FaRegUser />
                                <span className="username">{username}</span>
                            </Link>
                        </div>
                    ) : (
                        <div className="header__icon">
                            <Link className='link' to="/login">
                                <FaRegUser />
                            </Link>
                        </div>
                    )}
                </Col>
                <Col xs={12} className="header__search d-flex d-md-none mt-2">
                    <SearchBar />
                </Col>
            </Row>
        </Container>
    );
};

export default Header;
