// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const WishlistDisplay = () => {
//     const [wishlistItems, setWishlistItems] = useState([]);
//     const authorId = localStorage.getItem("author_id");

//     useEffect(() => {
//         const fetchWishlist = async () => {
//             if (!authorId) {
//                 toast.error("User not authenticated.");
//                 return;
//             }

//             try {
//                 const response = await axios.get('https://itilmaamapi.adef.tech/List_Wishlist', {
//                     params: { authorid: authorId },
//                 });

//                 if (Array.isArray(response.data)) {
//                     setWishlistItems(response.data);
//                 } else {
//                     toast.error("Failed to fetch wishlist.");
//                 }
//             } catch (error) {
//                 console.error("Error fetching wishlist:", error);
//                 toast.error("Failed to fetch wishlist.");
//             }
//         };

//         fetchWishlist();
//     }, [authorId]);
// debugger;
// const removeFromWishlist = async (productId) => {
//     const authorId = localStorage.getItem("author_id");
//     const url = `https://itilmaamapi.adef.tech/Delete_Wishlist`;

//     console.log("Removing product with ID:", productId);

//     try {
//         const response = await axios.post(url, null, {
//             params: {
//                 authorid: authorId,
//                 productid: productId,
//             },
//             headers: {
//                 "Content-Type": "application/x-www-form-urlencoded",
//             },
//         });

//         console.log("Response from server:", response.data); // Log the server response

//         if (response.data.responseCode === 1) {
//             toast.success("Product removed from wishlist!");
//             setWishlistItems(wishlistItems.filter(item => item.product_id !== productId));
//         } else {
//             toast.error(response.data.responsemessage || "Failed to remove product from wishlist.");
//         }
//     } catch (error) {
//         console.error("Error removing from wishlist:", error);
//         toast.error("Failed to remove product from wishlist.");
//     }
// };


//     return (
//         <div>
//             <h2>Your Wishlist</h2>
//             {wishlistItems.length === 0 ? (
//                 <p>No items in your wishlist.</p>
//             ) : (
//                 <ul>
//                     {wishlistItems.map(item => (
//                         <li key={item.product_id}>
//                             <img src={item.display_image} alt={item.name} width="100" />
//                             <span>{item.name}</span>
//                             <span>${item.price}</span>
//                             <button onClick={() => removeFromWishlist(item.product_id)}>Remove</button>
//                         </li>
//                     ))}
//                 </ul>
//             )}
//             <ToastContainer />
//         </div>
//     );
// };

// export default WishlistDisplay;

import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CreateWishlist from "./CreateWishlist";
import "react-toastify/dist/ReactToastify.css";
import "./Css/Wishlist.css"; // Create a new CSS file for wishlist styles
// import emptyWishlistImg from "./Images/wishlist_emptyimg.png"; // Placeholder image for empty wishlist

const WishlistDisplay = () => {
    const [wishlistItems, setWishlistItems] = useState([])
    const [wishlist, setWishlist] = useState([]);
    const authorId = localStorage.getItem("author_id");

    console.log("Author ID:", authorId);
    const cartId = localStorage.getItem("cart_id");

    debugger;
    useEffect(() => {
        const fetchWishlist = async () => {
            if (!authorId) {
                console.log("Fetching wishlist for authorId:", authorId);
                toast.error("User not authenticated.");
                
                return;
            }

            try {
                const response = await axios.get(`https://itilmaamapi.adef.tech/List_Wishlist`, {
                    params: { authorid: authorId },
                });
                console.log("Response from server:", response.data);
                console.log("Response from server:", response.data); // Log the server response
                if (Array.isArray(response.data)) {
                    setWishlistItems(response.data);
                } else {
                    toast.error("Failed to fetch wishlist.");
                }
            } catch (error) {
                console.error("Error fetching wishlist:", error);
                toast.error("Failed to fetch wishlist.");
            }
        };

        fetchWishlist();
    }, [authorId]);

    const removeFromWishlist = async (productId) => {
        try {
            const response = await axios.post("https://itilmaamapi.adef.tech/Delete_Wishlist", null, {
                params: {
                    authorid: authorId,
                    productid: productId,
                },
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            console.log("Response from server:", response.data); // Log the server response
            if (response.data.responseCode === 1) {
                toast.success("Product removed from wishlist!");
                removeFromWishlist(productId);
                setWishlistItems(wishlistItems.filter(item => item.product_id !== productId));
                
            } else {
                toast.error(response.data.responsemessage || "Failed to remove product from wishlist.");
            }
        } catch (error) {
            console.error("Error removing from wishlist:", error);
            toast.error("Failed to remove product from wishlist.");
        }
    };

debugger;
    const buyNow = async (productId) => {
        const cartId = localStorage.getItem("cart_id");
        const authorId = localStorage.getItem("author_id");
        if (!cartId || !authorId) {
            toast.error("Unable to add to cart. User or cart not found.");
            return;
        }
        try {
            const response = await axios.post(
                
                "https://itilmaamapi.adef.tech/AddCart",
                
                {
                    product_id: productId,
                    quantity: 1,
                    usertype: 4, // Ensure this matches your API requirements
                    authorid: authorId,
                    cart_id: cartId,
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );

            if (response.data.responseCode === 0) {
                localStorage.setItem("cart_id", response.data.cart_id);
                toast.success(response.data.responsemessage);
                removeFromWishlist(productId);
                window.location.href = "/delivery"; // Redirect after adding to cart
            } else {
                toast.error(response.data.responsemessage);
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
            toast.error("Error adding to cart.");
        }
    };

    return (
        <Container>
            {wishlistItems.length > 0 ? (
                <Row className="mt-5 mb-5">
                    <h2 className="wishlist-header">My Wishlist ({wishlistItems.length} Item{wishlistItems.length !== 1 && "s"})</h2>
                    <Col md={12} className="mt-5">
                        <div className="wishlist-details">
                            {wishlistItems.map(item => (
                                <Row className="mb-4 wishlist-item" key={item.product_id}>
                                    <Col md={2}>
                                    <img className="product-image" src={item.display_image} alt={item.name} />
                                    </Col>
                                    <Col md={4}>
                                        <p className="productname">{item.name}</p>
                                        <p className="categoryname">Price: ${item.price}</p>
                                    </Col>
                                    {/* <Col md={6} className="text-center">
                                        <div className="mt-3 d-flex">
                                            <p className="originalprice mt-2">$ {item.rate}</p>
                                            <p className="discountper mt-2">({item.discount}%)</p>
                                            <p className="productfinalprice">{item.rate}</p>
                                        </div>
                                        <div
                                            className="button-wishlist"
                                            onClick={() => buyNow(item.product_id)}
                                        >
                                            Add to Cart
                                        </div>
                                        <div className="remove-btn mr-5" onClick={() => removeFromWishlist(item.product_id)}>Remove</div>
                                    </Col> */}
                                    <Col md={6} className="text-center">
                                        <div className="mt-3 d-flex">
                                            <p className="originalprice mt-2">$ {item.rate}</p>
                                            <p className="discountper mt-2">({item.discount}%)</p>
                                            <p className="productfinalprice">${item.rate}</p>
                                        </div>
                                        <div className="wishlist-buttons d-flex justify-content-center mt-3">
                                            <div
                                                className="button-wishlist mr-3"
                                                onClick={() => buyNow(item.product_id)}
                                            >
                                                Add to Cart
                                            </div>
                                            <div className="remove-btn" onClick={() => removeFromWishlist(item.product_id)}>
                                                Remove
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                
                            ))}
                           
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row className="mt-5 mb-5 d-flex text-center justify-content-center empty-wishlist">
                    {/* <img src={} alt="" /> */}
                    <h5>Your wishlist is empty</h5>
                    <p>Add items to your wishlist to save them for later.</p>
                </Row>
            )}
            <ToastContainer />
        </Container>
    );
};

export default WishlistDisplay;
