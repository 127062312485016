// import React, { useState } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import axios from "axios";
// import { FaBookmark } from "react-icons/fa";
// import "./Css/Createwishlist.css";

// function CreateWishlist({ product }) {
//   const [loading, setLoading] = useState(false);
//   const cartId = localStorage.getItem("cart_id");
//   const cartcount = localStorage.getItem("cartcount");
//   const authorId = localStorage.getItem("author_id");
  

//   const addToWishlist = async () => {
//     setLoading(true);
//     const url = "https://itilmaamapi.adef.tech/Create_Wishlist";

//     debugger;
//     const data = {
//       product_id: product.id,
//       isdelete: false,
//       createdon: new Date().toISOString(),
//       createdby: authorId,
//       cart_id: product.cart_id,
//       display_image: product.display_image,
//       category_id: product.category_id,
//       color_id: product.color_id,
//       category_name: product.category_name,
//       price: product.price,
//       discount: product.discount,
//       per_discountamount: product.per_discountamount,
//       per_price: product.per_price,
//       offer: product.offer,
//       rate: product.rate,
//       discount_price: product.discount_price,
//       authorid: authorId,
//       rating: product.rating,
//       rating_count: product.rating_count,
//       cartcount: product.cartcount || 0,
//     };

//     try {
//       const response = await axios.post(url, data, {
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//       });

//       if (response.data.responseCode === 0) {
//         toast.success("Wishlist created successfully!", {
//           position: "top-right",
//           autoClose: 3000,
//         });
//       } else {
//         toast.error(response.data.responsemessage || "Failed to create wishlist.", {
//           position: "top-right",
//           autoClose: 3000,
//         });
//       }
//     } catch (error) {
//       toast.error("An error occurred while creating the wishlist.", {
//         position: "top-right",
//         autoClose: 3000,
//       });
//       console.error("Error creating wishlist:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
// debugger;
//   return (
//     // <div>
//     //   <button onClick={addToWishlist} disabled={loading}>
//     //    <FaRegHeart />
//     //   </button>
//     //   <ToastContainer />
//     // </div>
//     <div>
//     <button className="bookmark-button" onClick={addToWishlist} disabled={loading}>
//       <FaBookmark className="bookmark-icon" />
//     </button>
//     <span className="bookmark-text">Bookmark</span>
//     <ToastContainer />
//   </div>
//   );
// }

// export default CreateWishlist;

import React, { useState, useEffect } from "react"; // Import useEffect
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { FaBookmark } from "react-icons/fa";
import "./Css/Createwishlist.css";

function CreateWishlist({ product, onWishlistUpdate }) {
  const [loading, setLoading] = useState(false);
  const authorId = localStorage.getItem("author_id");
  const [wishlist, setWishlist] = useState([]); // State to hold wishlist items

  // Load saved wishlist from localStorage when the component mounts
  useEffect(() => {
    const savedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setWishlist(savedWishlist);
  }, []);

  // Save the wishlist to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const addToWishlist = async () => {
    setLoading(true);
    const url = "https://itilmaamapi.adef.tech/Create_Wishlist";

    const data = {
      product_id: product.id,
      isdelete: false,
      createdon: new Date().toISOString(),
      createdby: authorId,
      cart_id: product.cart_id,
      display_image: product.display_image,
      category_id: product.category_id,
      color_id: product.color_id,
      category_name: product.category_name,
      price: product.price,
      discount: product.discount,
      per_discountamount: product.per_discountamount,
      per_price: product.per_price,
      offer: product.offer,
      rate: product.rate,
      discount_price: product.discount_price,
      authorid: authorId,
      rating: product.rating,
      rating_count: product.rating_count,
      cartcount: product.cartcount || 0,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.responseCode === 0) {
        // Update local wishlist state and add the new product
        setWishlist((prevWishlist) => [...prevWishlist, product]);
        toast.success("Wishlist created successfully!", {
          position: "top-right",
          autoClose: 3000,
        });

        // Notify parent component about the wishlist update
        if (onWishlistUpdate) {
          onWishlistUpdate(product);
        }
      } else {
        toast.error(response.data.responsemessage || "Failed to create wishlist.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("An error occurred while creating the wishlist.", {
        position: "top-right",
        autoClose: 3000,
      });
      console.error("Error creating wishlist:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="bookmark-container">
  <button className="bookmark-button" onClick={addToWishlist} disabled={loading}>
    <FaBookmark className="bookmark-icon" />
  </button>
  <span className="bookmark-text">Bookmark</span>
  <ToastContainer />
</div>
  );
}

export default CreateWishlist;
